import { Project } from '../../../packages/editor/src/state/types';

const project: Project = {
	title: 'Neural Network',
	author: 'Andor Polgar',
	description: 'A simple neural network pre-trained as an XOR gate.',
	codeBlocks: [
		{
			code: [
				'module activationFunction',
				'',
				'float* in &sigmoid3.out',
				'int out',
				'',
				'push &out',
				'push *in',
				'',
				'push 0.5',
				'greaterThan',
				'if int',
				' push 1',
				'else',
				' push 0',
				'ifEnd',
				'store',
				'',
				'moduleEnd',
			],
			x: 262,
			y: -71,
			isOpen: true,
		},
		{
			code: [
				'module perceptron',
				'; Pre-trained perceptron',
				'',
				'float* in1 &switches.out1',
				'float* in2 &switches.out2',
				'float out',
				'',
				'; Training data',
				'const w1 10.056234',
				'const w2 -42.53689',
				'const bias -3.92453',
				'',
				'push &out',
				'push *in1',
				'push w1',
				'mul',
				'push *in2',
				'push w2',
				'mul',
				'add',
				'push bias',
				'add',
				'store',
				'moduleEnd',
			],
			x: 118,
			y: -81,
			isOpen: true,
		},
		{
			code: [
				'module perceptron2',
				'; Pre-trained perceptron',
				'',
				'float* in1 &switches.out1',
				'float* in2 &switches.out2',
				'float out',
				'',
				'; Training data',
				'const w1 -70.313797',
				'const w2 24.76387',
				'const bias -8.765101',
				'',
				'push &out',
				'push *in1',
				'push w1',
				'mul',
				'push *in2',
				'push w2',
				'mul',
				'add',
				'push bias',
				'add',
				'store',
				'moduleEnd',
			],
			x: 118,
			y: -55,
			isOpen: true,
		},
		{
			code: [
				'module perceptron3',
				'; Pre-trained perceptron',
				'',
				'float* in1 &sigmoid.out',
				'float* in2 &sigmoid2.out',
				'float out ',
				'',
				'; Training data',
				'const w1 6.94625',
				'const w2 6.64608',
				'const bias 6.05782',
				'',
				'push &out',
				'push *in1',
				'push w1',
				'mul',
				'push *in2',
				'push w2',
				'mul',
				'add',
				'push bias',
				'add',
				'store',
				'moduleEnd',
			],
			x: 190,
			y: -71,
			isOpen: true,
		},
		{
			code: ['module result', '', 'int* in &activationFunction.out', '', 'debug *in', '', 'moduleEnd'],
			x: 76,
			y: -61,
			isOpen: true,
		},
		{
			code: [
				'module sigmoid',
				'; Polynomial sigmoid ',
				'; funtion approximation',
				'',
				'float* in &perceptron.out',
				'float out',
				'',
				'push &out',
				'push *in',
				'push *in',
				'abs',
				'push 1.0',
				'add',
				'div',
				'store',
				'',
				'moduleEnd',
			],
			x: 154,
			y: -81,
			isOpen: true,
		},
		{
			code: [
				'module sigmoid2',
				'; Polynomial sigmoid ',
				'; funtion approximation',
				'',
				'float* in &perceptron2.out',
				'float out',
				'',
				'push &out',
				'push *in',
				'push *in',
				'abs',
				'push 1.0',
				'add',
				'div',
				'store',
				'',
				'moduleEnd',
			],
			x: 154,
			y: -55,
			isOpen: true,
		},
		{
			code: [
				'module sigmoid3',
				'; Polynomial sigmoid ',
				'; funtion approximation',
				'',
				'float* in &perceptron3.out',
				'float out',
				'',
				'push &out',
				'push *in',
				'push *in',
				'abs',
				'push 1.0',
				'add',
				'div',
				'store',
				'',
				'moduleEnd',
			],
			x: 226,
			y: -71,
			isOpen: true,
		},
		{ code: ['module switches', '', 'float out1 1.0', 'float out2 0.0', '', 'moduleEnd'], x: 81, y: -68, isOpen: true },
	],
	viewport: { x: 73, y: -83 },
	sampleRate: 50,
};

export default project;
