"use strict";
exports.__esModule = true;
exports["default"] = (function (_a) {
    var _b = _a.numberOfPorts, numberOfPorts = _b === void 0 ? 1 : _b, _c = _a.numberOfDataPlaceholders, numberOfDataPlaceholders = _c === void 0 ? 1 : _c;
    var ports = new Array(numberOfPorts).fill(0).map(function (item, index) { return index + 1; });
    var dataPlaceholders = new Array(numberOfDataPlaceholders).fill(0).map(function (item, index) { return index + 1; });
    return "module buffer\n\nint defaultValue 0\nint numberOfInputs ".concat(numberOfPorts, "\nint numberOfOutputs ").concat(numberOfPorts, "\nint numberOfDataPlaceholders ").concat(numberOfDataPlaceholders, "\n\n").concat(ports.map(function (index) { return "int* in:".concat(index, " &defaultValue"); }).join('\n'), "\n").concat(ports.map(function (index) { return "int out:".concat(index, " 0"); }).join('\n'), "\n\n").concat(dataPlaceholders.map(function (index) { return "int data:".concat(index, " 0"); }).join('\n'), "\n\n").concat(ports
        .map(function (index) { return "push &out:".concat(index, "\npush *in:").concat(index, "\nstore\n"); })
        .join('\n'), "\n\t\nmoduleEnd");
});
