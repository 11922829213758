"use strict";
exports.__esModule = true;
var consts_1 = require("./consts");
var masks = [
    1,
    2,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512,
    1024,
    2048,
    4096,
    8192,
    16384,
    32768,
];
exports["default"] = (function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.resolution, resolution = _c === void 0 ? 8 : _c;
    return "module adc\n\n; memory\nint default 0\nint* in &default\n\n".concat(masks
        .slice(0, resolution)
        .map(function (mask, index) { return "int out:".concat(index + 1, " 0"); })
        .join('\n'), "\n\n; locals\nlocal int input\n\n; code\npush *in\npush ").concat(resolution === 16 ? 1 : Math.floor(consts_1.I16_SIGNED_LARGEST_NUMBER / (Math.pow(2, resolution) - 1)), "\ndiv\nlocalSet input\n\n").concat(masks
        .slice(0, resolution)
        .map(function (mask, index) { return "\npush &out:".concat(index + 1, "\npush input\npush ").concat(mask, "\nand\npush 0\ngreaterThan\nif \n push ").concat(consts_1.LOGIC_HIGH, "\nelse\n push ").concat(consts_1.LOGIC_LOW, "\n ifEnd\nstore\n"); })
        .join('\n'), "\n\nmoduleEnd\n");
});
